<script setup lang="ts">
import { ref, computed } from "vue";

const props = withDefaults(
  defineProps<{
    type?:
    | "yellow"
    |"map"
    | "transfers"
    | "carrot"
    |"menu"
    | "darkYellow";
    to?: string;
    fontSize?: "base" | "lg" | "xl" | "";
    rounded?: "small" | "medium" | "large";
    height?: ""|"sm" | "small" | "medium" | "large";
    width?: "" | "small" | "medium" | "large"|"mobile";
    padding?: "absence" | "normal" | "";
    outlined?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    type: "yellow",
    to: "",
    fontSize: "base",
    rounded: "small",
    height: "small",
    width: "small",
    padding: "",
    outlined: false,
    loading: false,
    disabled: false,
  }
);

defineEmits(["click"]);

const stroke = ref("");

const cssClasses = computed(() => {
  let classes = [];
  switch (props.fontSize) {
    case "base":
      classes.push("text-base");
      break;
    case "lg":
      classes.push("text-lg");
      break;
    case "xl":
      classes.push("text-xl");
      break;
    default:
      classes.push("text-sm");
      break;
  }

  switch (props.rounded) {
    case "small":
      classes.push("rounded-[20px]");
      break;
    case "medium":
      classes.push("rounded-xl");
      break;
    case "large":
      classes.push("rounded-full");
      break;
    default:
      classes.push("rounded-[20px]");
      break;
  }

  switch (props.width) {
    case "small":
      classes.push("w-36");
      break;
    case "medium":
      classes.push("w-50 ");
      break;
    case "large":
      classes.push("w-123");
      break;
      case "mobile":
      classes.push("sm:w-73 w-full border-[2px] border-[#F9D849] !rounded-[10px]");
      break;
    default:
      classes.push("h-36");
      break;
  }
  switch (props.height) {
    case "sm":
      classes.push("h-[35px]");
      break;
    case "small":
      classes.push("h-[50px]");
      break;
    case "medium":
      classes.push("h-12");
      break;
    case "large":
      classes.push("h-14");
      break;
    default:
      classes.push("");
      break;
  }

  if (props.type === "yellow") {
    classes.push("bg-yellow font-medium");
    stroke.value = "#000000";
  } if (props.type === "map") {
    classes.push("bg-yellow hover:opacity-100 hover:bg-yellow opacity-85");
    stroke.value = "#000000";
  }
  if (props.type === "transfers") {
    classes.push("bg-white hover:bg-yellow font-medium");
  }
  if (props.type === "menu") {
    classes.push("bg-[#FAFAFA] hover:bg-darkYellow ");
  }

  if(props.disabled) {
    if(props.type === "yellow") {
      classes.push("bg-yellow !bg-[#E3E3E3] !border-[#E3E3E3] cursor-not-allowedcur");
    }
  }

  //   if (props.disabled && props.type === "yellow") {
  //     classes.push("bg-opacity-40 bg-darkYellow cursor-not-allowed");
  //   } else if (props.disabled) {
  //     classes.push(
  //       "bg-darkYellow bg-opacity-50 cursor-not-allowed"
  //     );
  //   }
  classes.push("hover:bg-darkYellow");
  return classes.join(" ");
});
</script>

<template>
  <nuxt-link v-if="to" :to="to" class="px-4 rounded block font-firasans hover:cursor-pointer text-center flex relative z-0"
    :class="cssClasses" :disabled="disabled" @click="$emit('click')">
    <span class="m-auto z-10" :class="[!loading ? 'opacity-100' : 'opacity-0']">
      <slot />
    </span>
    <span v-if="loading" class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-10">
    </span>
  </nuxt-link>
  <button v-else class="rounded text-center font-firasans text-lg flex relative" :class="cssClasses" :disabled="disabled"
    @click="!disabled ? $emit('click') : ''">
    <span class="m-auto z-10" :class="[!loading ? 'opacity-100' : 'opacity-0']">
      <slot />
    </span>
    <span v-if="loading" class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-10">
    </span>
  </button>
</template>
